import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const ErrorMsg = styled.p`
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.warning};
  ${getVariant({ variant: 'body1-normal' })}
`;

export const LinkWrapper = styled.div`
  margin: 40px 0;

  a {
    color: ${({ theme }) => theme.colors.tertiaryButtonText};
    font-size: 14px;
    font-family: ${({ theme }) => theme.typography.fontFamily.inter};
    text-decoration: none;
  }
`;
