import { useTheme } from 'styled-components';

import { Heading, Subheadline } from '@/core/components/Typography';
import { Logo } from '@/core/components/Logo';

import {
  Main,
  PageContent,
  GradientCircleTopRight,
  GradientCircleBottom,
  HeadingWrapper,
  ParagraphWrapper,
  LogoContainer,
} from '@/features/Auth/components/AuthLayout/AuthLayout.styles';

import { AuthLayoutProps } from './AuthLayout.types';

export const AuthLayout = ({ title, subtitle, children }: AuthLayoutProps) => {
  const { colors } = useTheme();

  return (
    <Main>
      <GradientCircleTopRight />
      <GradientCircleBottom />
      <PageContent>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <HeadingWrapper>
          <Heading type="h1">{title}</Heading>
        </HeadingWrapper>
        <ParagraphWrapper>
          <Subheadline
            type="normal"
            color={colors.secondaryText}
          >
            {subtitle}
          </Subheadline>
        </ParagraphWrapper>
        {children}
      </PageContent>
    </Main>
  );
};
