import { useFormContext } from 'react-hook-form';
import NextLink from 'next/link';

import { TextField } from '@/core/components/TextField';
import { Button } from '@/core/components/Button';
import { FormRow } from '@/core/components/FormElements.styles';
import { routePaths } from '@/core/constants/routes';

import { ErrorMsg, LinkWrapper } from './LoginForm.styles';
import { FormDataType, LoginFormProps } from './LoginForm.types';

export const LoginForm = ({ formError, onSubmit }: LoginFormProps) => {
  const { handleSubmit } = useFormContext<FormDataType>();

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormRow>
        <TextField
          name="email"
          type="text"
          label="Email address"
        />
      </FormRow>
      <FormRow>
        <TextField
          name="password"
          type="password"
          label="Password"
        />
      </FormRow>
      {formError && <ErrorMsg>{formError}</ErrorMsg>}
      <LinkWrapper>
        <NextLink href={routePaths.forgotPassword}>Forgot password?</NextLink>
      </LinkWrapper>
      <FormRow>
        <Button type="submit">LOGIN</Button>
      </FormRow>
    </form>
  );
};
